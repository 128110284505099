
  import SchoolMap from '@/components/SchoolMap.component.vue';
  import { Component, Vue } from 'vue-property-decorator';


  @Component({
    components: {
      'school-map': SchoolMap
    },
  })
  export default class Contact extends Vue {
    mobileClass= '';

    mounted() : void {
      this.mobileClass = (this as any).$mq === 'mobile' || (this as any).$mq === 'tablet' ? 'mobile' : '';
    }
  }
