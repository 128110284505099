
  import Vue from 'vue';
  import Component from 'vue-class-component';

  /**
   * @desc composant montrant une carte de l'école de Brens
   */
  @Component({
    components: {

    },
  })
  export default class SchoolMap extends Vue {
    /**
     * @desc clique sur la carte, envoie vers la un nouvel onglet gg maps
     */
    private mapClick() : void {
      window.open('https://www.google.fr/maps/place/Ecole/@45.7183627,5.6943511,601m/data=!3m1!1e3!4m5!3m4!1s0x0:0x1284ab28ba015f91!8m2!3d45.7184344!4d5.6961132', '_blank');
    }
  }
